
import axios from 'axios';
import Config from "../../config.json";

const API_BASE_URL = `${Config.REACT_APP_APIREST_BASE_URL}/v1/UserBuyerClient`;

const CONFIG_REQUEST = {
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': ''
    }
};    


const getByUserIdAsync = async (auth_token, id) => {    
    CONFIG_REQUEST.headers.Authorization = `Bearer ${auth_token}`;

    const response = await axios.get(`${API_BASE_URL}/GetByUserIdAsync/${id}`, CONFIG_REQUEST);
    const data = await response.data;       

    return data;
}


export const UserBuyerClientService = {   
    getByUserIdAsync  
}